import React from "react"
import createTemplate from "../../create-template"
import { useDialogState, Dialog, DialogBackdrop } from "reakit/Dialog"

import { useComponents } from "../../components"

function Randall({ editing, mode, styles, ...props }) {
  const Elements = useComponents()
  const checkboxes_color = styles.forElement("modal_content").color

  const dialog = useDialogState()

  const instagramDefaults = {
    settings: {
      layout: "grid",
      columns: 2,
      spacing: 10,
      radius: "small",
      shadow: "none",
      numberOfImages: 6,
      crop: "square",
      source: "instagram"
    },
    children: [
      {
        src:
          "https://images.unsplash.com/photo-1454442124827-b7de573f10e0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80",
        caption: "Lorem ipsum dolor sit amet."
      },
      {
        src:
          "https://images.unsplash.com/photo-1570387837926-5f6121b35818?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80",
        caption: "Consectetur adipiscing elit."
      },
      {
        src:
          "https://images.unsplash.com/photo-1573551701016-d173344231b0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80",
        caption: "Ut labore et dolore magna aliqua."
      },
      {
        src:
          "https://images.unsplash.com/photo-1499242015907-fd91d5d02f13?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80",
        caption: "Ut enim ad minim veniam."
      },
      {
        src:
          "https://images.unsplash.com/photo-1528844433838-6115c9c13a14?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80",
        caption: "Quis nostrud exercitation ullamco laboris."
      },
      {
        src:
          "https://images.unsplash.com/photo-1498878107635-df56252e004a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80",
        caption: "Nisi ut aliquip ex ea commodo consequat."
      }
    ]
  }

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div
        style={{
          backgroundColor: styles.forElement("background").backgroundColor
        }}
        className="formkit-bg-color"
      />
      <div
        style={styles.forElement("background")}
        className="formkit-bg-image"
      />
      <div className="formkit-container">
        <header>
          <Elements.Image
            className="formkit-image"
            name="main_image"
            size={{ w: 100, h: 100 }}
            aspectRatio={1}
            shape="circle"
            defaults={{
              src:
                "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/iM3Y3Hq4L37vdrG8MxY673"
            }}
          />
          <Elements.Heading
            className="formkit-heading"
            name="heading"
            defaults={{
              content: "Jakob Pieters"
            }}
          />
          <Elements.Content
            className="formkit-subtitle"
            name="subtitle"
            defaults={{
              content: "Photographer"
            }}
          />
        </header>
        <main>
          <Elements.Region
            className="formkit-content"
            name="content_one"
            group="content"
          >
            <Elements.Content
              defaults={{
                content:
                  "I'm a photographer and conservationist based in Cape Town, South Africa. I travel the world photographing ocean wildlife for National Geographic and have a particular interest in conserving the ocean, and saving the whales and sharks of the world."
              }}
            />
          </Elements.Region>
          <Elements.Button
            className="formkit-dialog-button"
            name="dialog_button"
            type="button"
            onClick={dialog.show}
            defaults={{
              content: "Join the Adventure"
            }}
          />
          {editing && (
            <button
              onClick={dialog.show}
              type="button"
              style={{
                background: "rgba(255,255,255,0.75)",
                cursor: "pointer",
                display: "block",
                fontFamily: "Open Sans, sans-serif",
                fontSize: 16,
                border: "1px solid #ccc",
                borderRadius: "3px",
                padding: "8px 8px",
                outline: 0,
                width: "200px",
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              Click to edit the form
            </button>
          )}
          <div
            className="formkit-accent"
            style={styles.forElement("accent")}
          ></div>
          <Elements.Region
            className="formkit-collection-container"
            name="content_two"
            group="content"
          >
            <Elements.Feed
              mode={mode}
              defaults={instagramDefaults.settings}
              defaultChildren={instagramDefaults.children}
            />
            <Elements.Content
              defaults={{
                content: ""
              }}
            />
          </Elements.Region>
          <Elements.BuiltWith background="background" image="background" />
          <DialogBackdrop
            {...dialog}
            className="formkit-dialog-overlay"
            style={{ position: editing ? "absolute" : "fixed" }}
          >
            <div className="formkit-modal-container">
              <Dialog
                {...dialog}
                className="formkit-dialog"
                style={styles.forElement("form")}
                unstable_portal={false}
                {...(editing
                  ? { modal: false, hideOnClickOutside: false }
                  : {})}
              >
                <Elements.Heading
                  className="formkit-heading"
                  name="modal_heading"
                  defaults={{
                    content: "Join the adventure"
                  }}
                />
                <Elements.Content
                  className="formkit-subtitle"
                  name="modal_subtitle"
                  defaults={{
                    content: "Whales, sharks, fish, oh my!"
                  }}
                />
                <Elements.Content
                  className="formkit-modal-content"
                  name="modal_content"
                  defaults={{
                    content:
                      "Each week on my newsletter I send out updates on my undersea adventures, share my latest work, and show you the conservation work that we're doing."
                  }}
                />
                <Elements.Form>
                  <Elements.Errors />
                  <Elements.CustomFields
                    style={{
                      color: checkboxes_color
                    }}
                  >
                    <Elements.AddFieldButton />
                    <Elements.Button
                      name="submit"
                      group="button"
                      defaults={{ content: "Subscribe to my newsletter" }}
                    />
                  </Elements.CustomFields>
                </Elements.Form>
                <Elements.Content
                  className="formkit-disclaimer"
                  name="disclaimer"
                  defaults={{
                    content: "I won’t send you spam. Unsubscribe anytime."
                  }}
                />
              </Dialog>
              <button className="formkit-modal__close" onClick={dialog.hide}>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fal"
                  data-icon="times"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    style={styles.forElement("close_button")}
                    fill="#ffffff"
                    d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                  />
                </svg>
              </button>
            </div>
          </DialogBackdrop>
        </main>
      </div>
    </Elements.LandingPage>
  )
}

export default createTemplate(Randall, { name: "Randall" })
